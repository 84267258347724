/* colour

Dark Blue
Hex: 002F86

Light Blue
Hex: 009CDE

*/
.fa-check-circle{
  color: green !important;
}
.fa-times-circle {
  color: red !important;
}
.btn {
  cursor: pointer;
}

h1,h2,h3,h4,h5 {
  font-family: 'Wendy One', sans-serif;
  color: #002F86;
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5 {
  color: #009CDE;
}

h3 {
  font-size: 40px;
}

body {
  font-family: 'Open Sans', sans-serif;
}

.btn-primary {
  background: #009CDE;
}

div.header {
  background: #009CDE;
  max-width: 100%;
}

#logo small {
  font-size: 65%;
}

.fas {
  color: #002F86;
}

.page-footer {
  background: #002F86;
}

input.domain-search {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 30px;
}
input.form-control {
  font-size: 22px;
  color: #333;
}
.domain-search-button {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding-left: 30px;
  padding-right: 30px;
  background: #7ED42C;
  border-color: #7ED42C;
  font-size: 22px;
}

/* trust-icon-container */
div.trust-icon-container {
  background: #85C3DE;
  max-width: 100%;
}
i.trust-icon {
  font-size: 30px;
}
div.trust-icon-container p {
  font-size: 20px;
  padding-top: 20px;
  margin-bottom: 0;
}

.results-btn {
  background: #7ED42C;
  border-color: #7ED42C;
  color: #fff !important;
  width: 250px;
}

/* bottom-bar */
div.bottom-bar {
  background: #041436;
}