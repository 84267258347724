.wrapper {
  display: grid;

  grid-template-areas:
    "header"
    "content"
    "footer";
}
.header {
  grid-area: header;
}
.content {
  grid-area: content;
  margin-top: 30px;
}

.footer {
  grid-area: footer;
}
